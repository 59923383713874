/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,700|Roboto:100,400,700");
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%; }

#root {
  height: 100%; }

.ant-form-item-label {
  margin-bottom: -12px; }

.ant-form-item {
  margin-bottom: 0; }

@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,700|Roboto:100,400,700);
/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #5c3580; }

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right,
.ant-menu-light.ant-menu-inline,
.ant-menu-light.ant-menu-vertical,
.ant-menu-light.ant-menu-vertical-left,
.ant-menu-light.ant-menu-vertical-right,
.ant-menu-item-selected {
  margin: 0px; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: 20px;
  line-height: 40px;
  margin-left: -3px; }

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 40px;
  height: 40px; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.anticon,
.ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon,
.ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon {
  font-size: 20px;
  line-height: 40px;
  margin-left: -3px;
  width: 22px; }

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 30px !important; }

.ant-layout-sider-children {
  margin-top: -4.1px; }

.iconSideMenu {
  width: 16px;
  fill: none;
  stroke-width: 3;
  stroke: white; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.welcome__leftContent {
  height: 100vh;
  background-color: #0f1448;
  color: #fff; }
  .welcome__leftContent .text--center {
    font-size: 40px;
    font-family: "Nunito", sans-serif; }
  .welcome__leftContent .logo {
    background-image: url(/static/media/Group-22.b9113c62.svg);
    background-size: 140px;
    background-repeat: no-repeat;
    height: 80px;
    width: 140px;
    margin-top: 20px; }
  .welcome__leftContent > .logout {
    cursor: pointer;
    font-size: 16px; }
    .welcome__leftContent > .logout > #logout-icon {
      font-size: 26px; }
    .welcome__leftContent > .logout > #logout--text {
      cursor: pointer;
      margin: 6px 0 0 10px; }

.welcome__rightContent {
  height: 100vh;
  display: flex;
  background-color: #f0f2f5;
  overflow-y: auto;
  padding: 30px; }
  .welcome__rightContent .menuWrapper {
    display: flexbox;
    justify-content: center;
    margin: auto;
    font-size: 20px;
    font-weight: bold; }
    .welcome__rightContent .menuWrapper__text {
      color: #0f1448;
      text-align: center; }
    .welcome__rightContent .menuWrapper__card {
      cursor: pointer !important;
      height: 150px;
      width: 150px;
      margin: 20px;
      color: #0f1448;
      border-radius: 50%;
      border: none !important; }
      .welcome__rightContent .menuWrapper__card .icon {
        fill: none;
        stroke: #0f1448;
        stroke-width: 2; }
        .welcome__rightContent .menuWrapper__card .icon :hover {
          fill: none;
          stroke: #e94734;
          stroke-width: 2; }

.welcome .enviroment {
  font-size: 20px;
  font-family: "Nunito", sans-serif; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.notFound {
  max-width: 450px;
  text-align: center;
  color: #00adff;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .notFound__icon {
    font-size: 60px;
    margin-bottom: 16px; }
  .notFound__text {
    font-weight: 700;
    font-size: 22px; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.form__front {
  padding: 6px 0 6px 0; }

.form__checkbox {
  line-height: 16px; }
  @media screen and (min-width: 768px) {
    .form__checkbox {
      padding-top: 20px; } }

.form__checkLabel {
  color: rgba(0, 0, 0, 0.85); }

.form__upperButtons {
  margin-bottom: 10px;
  float: left;
  display: flex; }

.form__divider {
  margin: 8px 0 8px 0; }

.form__bottomButtons {
  display: flex;
  justify-content: flex-end; }
  .form__bottomButtons--floatLeft {
    float: left; }
  .form__bottomButtons--floatRight {
    margin-left: 6px;
    float: right; }

.form .absoluteLeft {
  position: absolute;
  left: 0; }

.form .subgroups {
  background-color: #dfdfdf;
  margin-top: 10px;
  font-weight: bold;
  color: #1879c6;
  padding: 1.5px;
  padding-left: 7px; }
  .form .subgroups--box {
    border: 1px solid rgba(167, 167, 167, 0.753);
    margin-bottom: 18px;
    padding: 10px; }
  .form .subgroups--paralel-left {
    padding-left: 12px !important; }
  .form .subgroups--paralel-right {
    padding-right: 12px !important; }
  .form .subgroups--empty {
    padding: 0px; }

.form .ant-tabs .ant-tabs-top-content,
.form .ant-tabs .ant-tabs-bottom-content {
  min-height: 215px;
  overflow-y: auto;
  height: calc(100vh - 287px); }

.form .ant-input-number-focused,
.form .ant-input:not([disabled]):focus,
.form .ant-select-selection:not([disabled]):focus,
.form .ant-input-number:not([disabled]):focus {
  background-color: #ccd7ff; }

.form .ant-input-number,
.form .ant-time-picker,
.form .ant-calendar-picker {
  width: 100%; }

.form .anticon-close-circle {
  color: rgba(0, 0, 0, 0.25); }
  .form .anticon-close-circle :hover {
    color: rgba(0, 0, 0, 0.5); }

.ant-tabs-nav-wrap {
  padding-top: 8px;
  padding-bottom: 8px; }

.formDropdown {
  background-color: #ffffff;
  width: 600px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  padding: 12px !important; }
  @media screen and (max-width: 992px) {
    .formDropdown {
      width: 100%; } }

.dropdownItem {
  padding-right: 0px !important; }
  .dropdownItem .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }

.dropdownCustom {
  padding: 0 6px 0 0 !important;
  margin: 35px 0 0 0;
  text-align: right; }
  .dropdownCustom .ant-btn-icon-only {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  @media screen and (max-width: 768px) {
    .dropdownCustom {
      margin-top: 0px;
      text-align: left; } }

.uploadButton {
  width: 100%; }

form .ant-upload {
  width: 100%; }

.ant-upload-list-item-info {
  cursor: pointer;
  margin-right: 8px; }

.ant-upload-list-item .anticon-close {
  height: 20px;
  width: 20px;
  font-size: 20px; }
  .ant-upload-list-item .anticon-close :hover {
    padding: 2px;
    margin-top: -4px;
    background-color: #cf6767;
    color: #ffffff;
    border-radius: 50%;
    height: 20px;
    width: 20px; }

.downloadButton {
  height: 24px;
  right: 0;
  width: 70%;
  padding: 0 !important;
  float: right;
  margin-top: 9px; }

.ant-select {
  width: 100%; }

.customAvatar {
  margin-top: 6px; }

.fieldTooltip {
  margin-left: 4px; }

.dropdownCustom .ant-btn-icon-only.ant-btn-sm {
  width: 100%; }


/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.smartTable tr td:not(.ant-table-selection-column) {
  max-width: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.smartTable__upperButtons {
  margin-bottom: 10px; }

.smartTable .ant-btn.single-btn {
  margin-left: 10px; }

.smartTable tr.ant-table-row td:hover {
  white-space: pre-wrap !important;
  cursor: pointer; }

.smartTable tr td:hover input[type='button'],
.smartTable tr td:hover button {
  visibility: visible; }

.smartTable tr.row-no-hover > td:hover {
  cursor: text; }

.smartTable .ant-checkbox-indeterminate .ant-checkbox-inner {
  display: none; }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th {
  text-overflow: ellipsis;
  background-color: #e7e7e7; }
  .smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th .ant-table-column-sorter .ant-table-column-sorter-inner {
    cursor: pointer;
    font-size: 18px; }

.smartTable .smartTableBody .ant-table-body {
  height: calc(100vh - 264px); }

.smartTable .smartTableNotData .ant-table-empty .ant-table-body {
  height: 0px;
  overflow: hidden; }

.smartTable .ant-empty {
  height: calc(100vh - 360px); }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th {
  background-color: #e7e7e7; }

.smartTable tr.row-selected {
  background-color: #e2c6ff; }

.smartTable .ant-table-tbody > tr.row-striped:nth-child(even) {
  background-color: #f7f7f7; }

.smartTable .ant-table-thead th {
  white-space: nowrap;
  color: #5c5c5c; }

.smartTable .ant-table td {
  white-space: nowrap; }

.smartTable .navigation__button,
.smartTable .columns-settings {
  float: right; }

.smartTable th.drop-over-right {
  border-right: 2px dashed #ffffff !important; }

.smartTable th.drop-over-left {
  border-left: 2px dashed #ffffff; }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-tbody
> tr
td {
  padding: 6px; }

.smartTable > input[type='checkbox'] {
  padding: 2px; }

.smartTable .ant-table-small.ant-table-bordered .ant-table-content {
  height: calc(100vh - 227px); }

.smartTable .ant-table-pagination.ant-pagination {
  margin: 10px 0; }

.smartTable .react-resizable {
  position: relative;
  background-clip: padding-box; }

.smartTable .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.ant-tabs-bar {
  margin: 0; }

.tabContainer {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  border-top: 0;
  padding: 8px 6px 0 6px;
  background-color: #ffffff; }

.noDisplay {
  display: none; }

.rowContainer {
  padding-top: 4px;
  padding-bottom: 4px; }
  .rowContainer .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .rowContainer .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
    color: rgba(0, 0, 0, 0.85); }

.ant-drawer-body {
  padding: 0 14px 0 14px; }

.drawerContainer {
  width: 500px; }

.counter {
  margin-right: 10px; }

.ant-drawer-header {
  padding: 6px 12px 6px 16px; }

.ant-page-header-heading-sub-title {
  padding-top: 2px;
  font-size: 18px;
  color: black; }

.pageHeader {
  padding: 14px 6px 6px 6px; }
  .pageHeader__title {
    font-size: 18px; }
  .pageHeader__subTitle {
    font-size: 18px; }
  .pageHeader .ant-page-header-back {
    font-size: 24px; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.breadcrumb {
  padding: 0 0 8px 0;
  margin-top: -20px;
  display: block; }
  .breadcrumb__main--bold {
    font-size: 24px;
    font-weight: 600; }
  .breadcrumb__main--normal {
    font-size: 30px;
    font-weight: 200;
    padding: 0 8px 0 8px; }
  .breadcrumb__homeIcon {
    color: primary-hover; }
  .breadcrumb__chain {
    display: inline-block; }
    .breadcrumb__chain i.anticon {
      margin: 0 5px; }

.breadcrumbs-link {
  cursor: pointer; }
  .breadcrumbs-link__main-element {
    font-weight: bold; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.header__menuRight {
  float: right; }
  .header__menuRight .configUser {
    height: 100%;
    color: #0f1448;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer; }
    .header__menuRight .configUser__avatar {
      background-color: #0f1448; }
      .header__menuRight .configUser__avatar .anticon {
        font-size: 20px; }
    .header__menuRight .configUser__user {
      padding-left: 8px;
      padding-right: 8px; }
      .header__menuRight .configUser__user :hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); }

.ant-layout-header {
  z-index: 9;
  background: transparent; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.mainWrapper {
  height: 100vh; }
  .mainWrapper__sider {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    position: fixed;
    left: 0;
    min-width: 215px !important; }
    .mainWrapper__sider--collapsed {
      overflow-y: auto; }
  .mainWrapper .main {
    background-color: #efefef; }
    .mainWrapper .main.collapsed {
      margin-left: 0px; }
    .mainWrapper .main.normal {
      margin-left: 217px; }
    .mainWrapper .main__content {
      background-color: #efefef;
      padding: 0 14px 0 14px;
      margin: 0;
      height: 100%; }
      .mainWrapper .main__content--home {
        margin: 0;
        height: 100%; }
  .mainWrapper .notFound {
    height: 80%; }
  .mainWrapper .ant-layout-sider-zero-width-trigger {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    font-size: 30px;
    z-index: 1; }
    .mainWrapper .ant-layout-sider-zero-width-trigger .anticon {
      color: rgba(255, 255, 255, 0.65); }
  .mainWrapper .logoWrapper {
    height: 55px; }
    .mainWrapper .logoWrapper__logoCollapsed {
      background-image: url(/static/media/group-24.4ae7eb1c.svg);
      height: 55px;
      width: 60px;
      position: relative;
      left: 7px;
      top: 11px; }
    .mainWrapper .logoWrapper__logoExtended {
      background-image: url(/static/media/Group-22.b9113c62.svg);
      background-size: 120px;
      background-repeat: no-repeat;
      height: 40px;
      width: 124px;
      float: left;
      margin-left: 44px;
      margin-top: 12px; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.login {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  background-color: #0f1448; }
  .login__leftContent {
    padding-left: 0; }
    .login__leftContent .text {
      font-size: 35px;
      display: block;
      padding-top: 100px;
      font-family: "Nunito", sans-serif; }
      .login__leftContent .text--white {
        color: white; }
    .login__leftContent .enviroment {
      font-size: 20px;
      font-family: "Nunito", sans-serif; }
    .login__leftContent .logo {
      background-image: url(/static/media/Group-22.b9113c62.svg);
      background-size: 180px;
      background-repeat: no-repeat;
      height: 80px;
      margin-top: 23px;
      opacity: 0.9; }
  .login__rightContent {
    background-color: white;
    border-radius: 6px;
    width: 370px; }
    .login__rightContent .progressBar {
      position: 'absolute';
      top: -10px; }
    .login__rightContent .ant-form-item {
      margin-top: 5px; }
    .login__rightContent form {
      padding: 50px; }
      .login__rightContent form label {
        padding-top: 50px;
        font-size: 35px;
        color: #0f1448;
        font-weight: bold; }
      .login__rightContent form input {
        border-bottom-color: black;
        border-top-color: white;
        border-left-color: white;
        border-right-color: white;
        color: black;
        margin-bottom: 0px !important; }
      .login__rightContent form i {
        color: #e94734;
        opacity: 0.5;
        margin-bottom: 0px; }
    .login__rightContent .buttonLogin {
      height: 50px;
      width: 60%;
      margin-top: 10px;
      background-color: #e94734;
      border-color: #e94734;
      margin-left: 115px;
      margin-top: 30px; }
    .login__rightContent .buttonForgotPassword {
      margin-left: 74px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #4a4a4a; }
  .login .enviromentMovile {
    display: none; }

@media only screen and (max-width: 890px) {
  .login {
    height: 100%;
    width: 100%; }
    .login__leftContent {
      display: none; }
    .login .enviromentMovile {
      font-size: 20px;
      font-family: "Nunito", sans-serif;
      color: white;
      display: block;
      padding-bottom: 5px; }
    .login .logoMovile {
      background-image: url(/static/media/Group-22.b9113c62.svg);
      background-size: 180px;
      background-repeat: no-repeat;
      height: 90px;
      display: block;
      margin-left: 100px;
      margin-bottom: 40px; } }

@media only screen and (max-width: 400px) {
  .login {
    height: 100%;
    width: 100%; }
    .login__leftContent {
      display: none; }
    .login .enviromentMovile {
      font-size: 20px;
      font-family: "Nunito", sans-serif;
      color: white;
      display: block;
      padding-bottom: 5px; }
    .login .logoMovile {
      margin-left: 50px;
      margin-bottom: 60px; }
    .login__rightContent {
      padding: 2px;
      width: 275px; }
      .login__rightContent .progressBar {
        top: -10px; }
      .login__rightContent form {
        padding: 35px; }
      .login__rightContent button {
        height: 50px;
        width: 60%;
        margin-top: 10px;
        background-color: #e94734;
        margin-left: 40px; } }


/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.updatePassword {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  background-color: #0f1448; }
  .updatePassword__logo {
    background-image: url(/static/media/Group-22.b9113c62.svg);
    background-repeat: no-repeat;
    height: 90px;
    width: 180px;
    margin-bottom: 38px;
    margin-top: -58px;
    margin-left: 144px; }
  .updatePassword__boxContent {
    background-color: white;
    border-radius: 6px;
    width: 500px;
    height: 350px; }
    .updatePassword__boxContent form {
      padding: 50px; }
      .updatePassword__boxContent form label {
        font-size: 16px;
        font-weight: 400; }
      .updatePassword__boxContent form .label--text {
        padding-top: 50px;
        font-size: 22px;
        color: #0f1448;
        font-weight: bold; }
      .updatePassword__boxContent form button {
        height: 50px;
        width: 60%;
        margin-top: 10px;
        margin-left: 80px; }
      .updatePassword__boxContent form input {
        border-bottom-color: black;
        border-top-color: white;
        border-left-color: white;
        border-right-color: white;
        color: black;
        margin-bottom: 0px !important; }
      .updatePassword__boxContent form .updateButtom {
        padding-left: 76px; }

a.ant-btn {
  height: 50px;
  padding-top: 9px;
  margin-left: 10px;
  width: 60%; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%; }

#root {
  height: 100%; }

.ant-form-item-label {
  margin-bottom: -12px; }

.ant-form-item {
  margin-bottom: 0; }


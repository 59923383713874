.uploadButton {
  width: 100%; }

form .ant-upload {
  width: 100%; }

.ant-upload-list-item-info {
  cursor: pointer;
  margin-right: 8px; }

.ant-upload-list-item .anticon-close {
  height: 20px;
  width: 20px;
  font-size: 20px; }
  .ant-upload-list-item .anticon-close :hover {
    padding: 2px;
    margin-top: -4px;
    background-color: #cf6767;
    color: #ffffff;
    border-radius: 50%;
    height: 20px;
    width: 20px; }

.downloadButton {
  height: 24px;
  right: 0;
  width: 70%;
  padding: 0 !important;
  float: right;
  margin-top: 9px; }

.ant-select {
  width: 100%; }

.customAvatar {
  margin-top: 6px; }

.fieldTooltip {
  margin-left: 4px; }

.dropdownCustom .ant-btn-icon-only.ant-btn-sm {
  width: 100%; }

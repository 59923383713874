/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.header__menuRight {
  float: right; }
  .header__menuRight .configUser {
    height: 100%;
    color: #0f1448;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer; }
    .header__menuRight .configUser__avatar {
      background-color: #0f1448; }
      .header__menuRight .configUser__avatar .anticon {
        font-size: 20px; }
    .header__menuRight .configUser__user {
      padding-left: 8px;
      padding-right: 8px; }
      .header__menuRight .configUser__user :hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); }

.ant-layout-header {
  z-index: 9;
  background: transparent; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #5c3580; }

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right,
.ant-menu-light.ant-menu-inline,
.ant-menu-light.ant-menu-vertical,
.ant-menu-light.ant-menu-vertical-left,
.ant-menu-light.ant-menu-vertical-right,
.ant-menu-item-selected {
  margin: 0px; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: 20px;
  line-height: 40px;
  margin-left: -3px; }

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 40px;
  height: 40px; }

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.anticon,
.ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon,
.ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon {
  font-size: 20px;
  line-height: 40px;
  margin-left: -3px;
  width: 22px; }

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 30px !important; }

.ant-layout-sider-children {
  margin-top: -4.1px; }

.iconSideMenu {
  width: 16px;
  fill: none;
  stroke-width: 3;
  stroke: white; }

/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.updatePassword {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
  background-color: #0f1448; }
  .updatePassword__logo {
    background-image: url("../img/login/Group-22.svg");
    background-repeat: no-repeat;
    height: 90px;
    width: 180px;
    margin-bottom: 38px;
    margin-top: -58px;
    margin-left: 144px; }
  .updatePassword__boxContent {
    background-color: white;
    border-radius: 6px;
    width: 500px;
    height: 350px; }
    .updatePassword__boxContent form {
      padding: 50px; }
      .updatePassword__boxContent form label {
        font-size: 16px;
        font-weight: 400; }
      .updatePassword__boxContent form .label--text {
        padding-top: 50px;
        font-size: 22px;
        color: #0f1448;
        font-weight: bold; }
      .updatePassword__boxContent form button {
        height: 50px;
        width: 60%;
        margin-top: 10px;
        margin-left: 80px; }
      .updatePassword__boxContent form input {
        border-bottom-color: black;
        border-top-color: white;
        border-left-color: white;
        border-right-color: white;
        color: black;
        margin-bottom: 0px !important; }
      .updatePassword__boxContent form .updateButtom {
        padding-left: 76px; }

a.ant-btn {
  height: 50px;
  padding-top: 9px;
  margin-left: 10px;
  width: 60%; }

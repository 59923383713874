/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.breadcrumb {
  padding: 0 0 8px 0;
  margin-top: -20px;
  display: block; }
  .breadcrumb__main--bold {
    font-size: 24px;
    font-weight: 600; }
  .breadcrumb__main--normal {
    font-size: 30px;
    font-weight: 200;
    padding: 0 8px 0 8px; }
  .breadcrumb__homeIcon {
    color: primary-hover; }
  .breadcrumb__chain {
    display: inline-block; }
    .breadcrumb__chain i.anticon {
      margin: 0 5px; }

.breadcrumbs-link {
  cursor: pointer; }
  .breadcrumbs-link__main-element {
    font-weight: bold; }

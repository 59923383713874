/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.welcome__leftContent {
  height: 100vh;
  background-color: #0f1448;
  color: #fff; }
  .welcome__leftContent .text--center {
    font-size: 40px;
    font-family: "Nunito", sans-serif; }
  .welcome__leftContent .logo {
    background-image: url("../img/login/Group-22.svg");
    background-size: 140px;
    background-repeat: no-repeat;
    height: 80px;
    width: 140px;
    margin-top: 20px; }
  .welcome__leftContent > .logout {
    cursor: pointer;
    font-size: 16px; }
    .welcome__leftContent > .logout > #logout-icon {
      font-size: 26px; }
    .welcome__leftContent > .logout > #logout--text {
      cursor: pointer;
      margin: 6px 0 0 10px; }

.welcome__rightContent {
  height: 100vh;
  display: flex;
  background-color: #f0f2f5;
  overflow-y: auto;
  padding: 30px; }
  .welcome__rightContent .menuWrapper {
    display: flexbox;
    justify-content: center;
    margin: auto;
    font-size: 20px;
    font-weight: bold; }
    .welcome__rightContent .menuWrapper__text {
      color: #0f1448;
      text-align: center; }
    .welcome__rightContent .menuWrapper__card {
      cursor: pointer !important;
      height: 150px;
      width: 150px;
      margin: 20px;
      color: #0f1448;
      border-radius: 50%;
      border: none !important; }
      .welcome__rightContent .menuWrapper__card .icon {
        fill: none;
        stroke: #0f1448;
        stroke-width: 2; }
        .welcome__rightContent .menuWrapper__card .icon :hover {
          fill: none;
          stroke: #e94734;
          stroke-width: 2; }

.welcome .enviroment {
  font-size: 20px;
  font-family: "Nunito", sans-serif; }

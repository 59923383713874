/*
*  COLORS
*/
/*
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
.mainWrapper {
  height: 100vh; }
  .mainWrapper__sider {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    position: fixed;
    left: 0;
    min-width: 215px !important; }
    .mainWrapper__sider--collapsed {
      overflow-y: auto; }
  .mainWrapper .main {
    background-color: #efefef; }
    .mainWrapper .main.collapsed {
      margin-left: 0px; }
    .mainWrapper .main.normal {
      margin-left: 217px; }
    .mainWrapper .main__content {
      background-color: #efefef;
      padding: 0 14px 0 14px;
      margin: 0;
      height: 100%; }
      .mainWrapper .main__content--home {
        margin: 0;
        height: 100%; }
  .mainWrapper .notFound {
    height: 80%; }
  .mainWrapper .ant-layout-sider-zero-width-trigger {
    position: sticky;
    bottom: 0;
    font-size: 30px;
    z-index: 1; }
    .mainWrapper .ant-layout-sider-zero-width-trigger .anticon {
      color: rgba(255, 255, 255, 0.65); }
  .mainWrapper .logoWrapper {
    height: 55px; }
    .mainWrapper .logoWrapper__logoCollapsed {
      background-image: url("../img/group-24.svg");
      height: 55px;
      width: 60px;
      position: relative;
      left: 7px;
      top: 11px; }
    .mainWrapper .logoWrapper__logoExtended {
      background-image: url("../img/login/Group-22.svg");
      background-size: 120px;
      background-repeat: no-repeat;
      height: 40px;
      width: 124px;
      float: left;
      margin-left: 44px;
      margin-top: 12px; }
